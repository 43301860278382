<template>
  <div
    class="vModalSuccess"
    :class="{ light: theme === 'light' }"
  >
    <div class="vModalSuccess__overlay" />
    <div class="vModalSuccess__content">
      <!-- <vCloseButton
        v-if="closeBth"
        @handleClick="closeModal"
      /> -->
      <slot name="img" />

      <h2 class="vModalSuccess__title">
        <slot name="title" />
      </h2>
      <p class="vModalSuccess__description">
        <slot name="description" />
      </p>
    </div>
  </div>
</template>

<script>
// import vCloseButton from '@/components/v-close-button'
export default {
  name: 'VModalSuccess',
  components: {
    // vCloseButton
  },
  props: {
    closeBth: {
      type: Boolean,
      require: false,
      default: false,
    },
    theme: {
      type: String,
      require: false,
      default: 'dark',
    },
  },
  emits: ['closeModal'],
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>

<style lang="scss">
.vModalSuccess {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-family: 'Commissioner';
  text-align: center;
  color: #fbfbfc;
  z-index: 10;
  &.light {
    .vModalSuccess__overlay {
      background: #f7f8fa;
    }
    .vModalSuccess__title {
      color: #010d35;
    }
    .vModalSuccess__description {
      color: #343d5d;
    }
  }
  &__overlay {
    background: rgba(33, 33, 41, 0.95);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  &__content {
    position: relative;
    min-width: 310px;
    max-width: 310px;
    padding: 16px;
    // border: 2px solid #2C3038;
    border-radius: 16px;
    z-index: 11;
  }
  &__title {
    max-width: 279px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
  &__description {
    max-width: 279px;
    font-size: 16px;
    line-height: 20px;
  }
}
</style>
