<template>
  <div class="sign">
    <div
      v-if="GET_ERROR_FORGET_ID"
      class="sign__container"
    >
      <h2 class="sign__title">
        Новый пароль
      </h2>
      <Form
        v-slot="{ errors }"
        class="sign__form"
        @submit="checkForm"
      >
        <!-- Пароль -->
        <vInput
          id="password"
          v-model="password"
          type="password"
          :value="password"
          placeholder="Пароль"
          :required="true"
          rule="min:6"
        />

        <!-- Подтверждение пароля -->
        <vInput
          id="confirmation"
          v-model="confirmPassword"
          type="password"
          :value="confirmPassword"
          placeholder="Подтверждение пароля"
          :required="true"
          rules="confirmed:@password"
        />
        <vButton
          type="submit"
          :disabled="Object.keys(errors).length > 0"
        >
          Сохранить
        </vButton>
      </Form>
      <img
        src="~@/assets/images/logo.svg"
        class="sign__logo"
        alt="logo"
      >
      <p class="sign__copyright">
        &copy; powered by tapper {{ new Date().getFullYear() }}
      </p>
    </div>
  </div>

  <!-- Modal Wait -->
  <vModalSuccess
    v-if="GET_RECOVERYPASSWORD_SUCCESS"
    :close-bth="false"
    theme="light"
  >
    <template #title>
      Новый пароль успешно изменен
    </template>
    <template #description>
      Через 2 секунды вы будете перенаправлены на страницу авторизации
    </template>
  </vModalSuccess>
</template>

<script>
import { Form } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
import vModalSuccess from '@/components/modals/v-modal-success'
export default {
  name: 'ForgetPasswordIdPage',
  components: {
    vInput,
    vButton,
    Form,
    vModalSuccess,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
    }
  },
  computed: {
    ...mapGetters('user', [
      'GET_ERROR_FORGET_ID',
      'GET_RECOVERYPASSWORD_SUCCESS',
    ]),
  },
  created() {
    this.checkId(this.$route.params.id)
  },
  methods: {
    ...mapActions('user', ['CHECK_ID_FORGET_PASSWORD', 'PASSWORD_RECOVERY']),
    ...mapActions('preloader', ['TOGGLE_PRELOADER']),
    async checkId(id) {
      await this.CHECK_ID_FORGET_PASSWORD(id)
      if (!this.GET_ERROR_FORGET_ID) {
        this.$router.push('/')
      }
      this.TOGGLE_PRELOADER(false)
    },
    async checkForm() {
      await this.PASSWORD_RECOVERY({
        code: this.$route.params.id,
        password: this.password,
      })
      this.password = ''
      this.confirmPassword = ''
      setTimeout(() => {
        this.$router.push('/users')
      }, 2000)
    },
  },
}
</script>
